import React, { useEffect, useState } from 'react';
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';

const customStyles = {
  rows: {
    style: {
      minHeight: '48px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      backgroundColor: '#cee0eb',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const SubCategory = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategoryName, setSubcategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const columns = [
    { name: 'SL', selector: row => row.sl },
    { name: 'Service Name', selector: row => row.Category },
    { name: 'Subservice Name', selector: row => row.Subcategory },
    { name: 'Date of Creation', selector: row => row.createdAt },
    { name: 'Action', selector: row => row.action },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [categoriesRes, subCategoriesRes] = await Promise.all([
        HttpClientXml.requestData('get-all-service-category', "GET", {}),
        HttpClientXml.requestData('subCategory', "GET", {})
      ]);

      if (categoriesRes && categoriesRes.status) {
        setCategories(categoriesRes.data);
      } else {
        toast.error("Failed to fetch categories");
      }

      if (subCategoriesRes && subCategoriesRes.data) {
        const apiData = subCategoriesRes.data.map((item, i) => {
          const category = categoriesRes.data.find(cat => cat._id === item.categoryId);
          return {
            sl: i + 1,
            Category: category ? category.categoryName : 'Unknown',
            Subcategory: item.subCategoryName,
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            action: (
              <EditDeleteIcon
                onClickEdit={() => handleEdit(item)}
                onClickDelete={() => handleDelete(item._id)}
              />
            ),
          };
        });

        setTableData(apiData.reverse());
      } else {
        toast.error("Failed to fetch subcategories");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);

  const handleSubcategoryChange = (e) => setSubcategoryName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory || !subcategoryName) {
      return toast.error("Please select a service and enter a subservice name");
    }

    const data = {
      categoryId: selectedCategory,
      subCategoryName: subcategoryName,
    };

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData("subCategory", "POST", data);
      if (res) {
        toast.success("Subservice Added Successfully");
        setSelectedCategory('');
        setSubcategoryName('');
        navigate('/subcategory')
        fetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding subcategory");
      console.error("Error adding subcategory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedCategory(item.categoryId);
    setSubcategoryName(item.subCategoryName);
    setUpdateID(item._id);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory || !subcategoryName) {
      return toast.error("Please select a service and enter a subservice name");
    }

    const data = {
      categoryId: selectedCategory,
      subCategoryName: subcategoryName,
    };

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(`subCategory/${updateID}`, "PUT", data);
      if (res) {
        toast.success("Subservice Updated Successfully");
        setSelectedCategory('');
        setSubcategoryName('');
        setIsEdit(false);
        fetchData();
        navigate('/subcategory')
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating subcategory");
      console.error("Error updating subcategory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (id) => {
    const del = async () => {
      const data = { isDeleted: true };
      setIsLoading(true);
      try {
        const res = await HttpClientXml.requestData(`subCategory/${id}`, "PUT", data);
        if (res) {
          toast.success("Deleted Successfully");
          fetchData();
          navigate('/subcategory')
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting subcategory");
        console.error("Error deleting subcategory:", error);
      } finally {
        setIsLoading(false);
      }
    };
    DeleteConfirmModal(del);
  };

  return (
    <div>
      <CustomLoader loading={isLoading} />

      <div className="d-flex justify-content-end">
        <div className="form-header">
          <section className="piechartsBox_area">
            <div style={headLineStyle} className="page-headline">
              Add & View Services and Subservice
            </div>
            <form className="mb-2">
              <div className="row">
                <div className="col-4">
                  <label htmlFor="categoryDropdown" className='edit-tag'>
                    Select Service<span className="text-danger">&nbsp;*</span>
                  </label>
                  <select
                    id="categoryDropdown"
                    className="form-control"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    <option value="">Select a Service</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-4">
                  <label htmlFor="subcategoryInput" className='edit-tag'>
                    SubService Name<span className="text-danger">&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    id="subcategoryInput"
                    className="form-control"
                    placeholder="Subservice Name"
                    onChange={handleSubcategoryChange}
                    value={subcategoryName}
                    name="subcategory"
                  />
                </div>

                <div className="col-4 d-flex align-items-center justify-content-center">
                  {!isEdit ? (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-grad w-50 d-flex justify-content-center align-items-center"
                      style={{
                        background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                        color: "#fff",
                        height: "40px",
                        textAlign: "center",
                        marginTop: "25px"
                      }}
                    >
                      Add Subcategory
                    </button>
                  ) : (
                    <div className="d-flex align-items-center" style={{gap:'7px'}}>
                      <button
                        type="submit"
                        onClick={handleEditSubmit}
                        className="btn-grad"
                        style={{
                          background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                          color: "#fff",
                          height: "40px",
                          width: "200px",  // Adjust width as needed
                          textAlign: "center",
                          marginTop: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        Update Subcategory
                      </button>
                      <ImCross
                        onClick={() => { setIsEdit(false); setSelectedCategory(''); setSubcategoryName(''); }}
                        style={{
                          fontSize: "30px",
                          cursor: "pointer",
                          marginTop: "25px",  // Align with button margin
                          lineHeight: "1"  // Ensure no extra line height affects alignment
                        }}
                        className="text-danger ms-2"
                      />
                    </div>

                  )}
                </div>

              </div>
            </form>

            <div className="mt-2">
              <DataTable
                columns={columns}
                data={tableData}
                pagination
                striped
                customStyles={customStyles}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SubCategory;
