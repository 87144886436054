import React, { useEffect, useState } from 'react';
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';
import noimage from '../../Images/no-image.png'
import loadingimage from '../../Images/loading256.gif'
import './modal.css';

const customStyles = {
  rows: {
    style: {
      minHeight: '48px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      backgroundColor: '#cee0eb',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const Templates = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate()
  const [templateTypes, setTemplateTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [templateType, setTemplateType] = useState('');
  const [category, setCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [tasksByTemplate, setTasksByTemplate] = useState([]);

  const [subcategoryName, setSubcategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const [taskTemplateId, setTaskTemplateId] = useState("")
  const [mainTaskAvailable, setMainTaskAvailable] = useState(false)
  const [activeTemplateTypeName, setActiveTemplateTypeName] = useState("")
  const [activeCategoryName, setActiveCategoryName] = useState("")

  const [displayTemplateListing, setDisplayTemplateListing] = useState(false)
  const [displayBasicInfo, setDisplayBasicInfo] = useState(true)
  const [displayManageTask, setDisplayManageTask] = useState(false)
  const [displayMilestone, setDisplayMilestone] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)

  const columns = [
    { name: 'SL', selector: row => row.sl },
    {
      name: 'Image', selector: row => {
        return (
          <>
            <img src={`${row.ImageUrl}`} style={{ width: "72px" }} />
          </>
        )
      }
    },
    { name: 'Type', selector: row => row.Type },
    { name: 'Category', selector: row => row.Category },
    {
      name: 'Sub Category', selector: row => {
        return (
          <>
            <ul>
              {
                row.SubCategory.map((item, key) => {
                  return (
                    <>
                      <li key={key}>{item}</li>
                    </>
                  )
                })
              }
            </ul>
          </>
        )
      }
    },
    { name: 'Mode', selector: row => row.Mode },
    { name: 'Date of Creation', selector: row => row.createdAt },
    { name: 'Tasks', selector: row => row.Tasks },
    { name: 'Action', selector: row => row.action },
  ];

  const fetchTemplateTypes = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData('template-type', "GET", {});

      if (res && res.status) {
        setTemplateTypes(res.data);
      } else {
        toast.error("Failed to fetch types");
      }

    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData('get-all-service-category', "GET", {});

      if (res && res.status) {
        setCategories(res.data);
      } else {
        toast.error("Failed to fetch types");
      }

    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTasksByTemplate = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(`template-task/${taskTemplateId}`, "GET", {});
      //console.log(res);

      if (res && res.status) {
        setTasksByTemplate(res.data);
      } else {
        toast.error("Failed to fetch tasks");
      }

    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    if (!categoryId) {
      setSubCategories([])
      return;
    }
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(`subCategory/${categoryId}`, "GET", {});



      // if (res && res.status) {
      if (res) {
        setSubCategories(res.data);
      } else {
        toast.error("Failed to fetch types");
      }

    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };



  const handleNextAddTask = () => {



  }


  const [allTemplateData, setAllTemplateData] = useState([])
  // 
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData("template", "GET", {});

      if (res && res.status) {
        setAllTemplateData(res.data)
        const apiData = res.data.map((item, i) => {
          return {
            sl: i + 1,
            Type: item.templateType ? item.templateType : 'N/A',
            Category: item.category ? item.category : 'N/A',
            SubCategory: item.subCategory ? item.subCategory : 'N/A',
            ImageUrl: item.image ? item.image : noimage,
            Mode: item.mode ? item.mode : 'N/A',
            Tasks: item.templateTaskDetails ? item.templateTaskDetails : [],
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            Tasks: (
              <button
                className="btn btn-primary btn-sm"
              // onClick={() => handleTask(item)}
              >
                Manage Task</button>
            ),
            action: (
              <EditDeleteIcon
                onClickEdit={() => handleEdit(item)}
                onClickDelete={() => handleDelete(item._id)}
              />
            ),
          };
        });

        setTableData(apiData.reverse());
      } else {
        toast.error("Failed to fetch subcategories");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplateTypes();
    fetchCategories();
    fetchData();
  }, []);


  useEffect(() => {
    templateType === "addnew" && navigate('/template-types');
  }, [templateType])

  useEffect(() => {
    fetchTasksByTemplate()
  }, [displayMilestone])



  const handleTemplateTypeChange = (e) => setTemplateType(e.target.value);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    fetchSubCategories(e.target.value);
  };

  const handleSubcategoryChange = (e) => setSubcategoryName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!templateType || !category) {
      return toast.error("Please select template type and category");
    } else if (!selectedSubCategories.length) {
      return toast.error("Choose at least one sub category");
    }

    const data = {
      templateType: templateType,
      category: category,
      subCategory: selectedSubCategories,
      description: description,
      image: image,
      mode: selectedOption,
    };

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData("template", "POST", data);
      if (res) {
        toast.success("Basic info saved successfully");
        setTaskTemplateId(res?.data?._id)
        resetData()
        setDisplayManageTask(true)
        setDisplayBasicInfo(false)
        fetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // toast.error("An error occurred while adding subcategory");
      // console.error("Error adding subcategory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setTemplateType(item?.templateTypeId);
    setCategory(item?.categoryId);
    fetchSubCategories(item?.categoryId);
    setSelectedSubCategories(item?.subCategoryId);
    setDescription(item?.description);
    setSelectedOption(item?.mode);
    setUpdateID(item?._id);
  };

  const resetData = () => {
    let file = document.querySelector("#images");
    file.value = "";
    setImage(noimage)
    setTemplateType("");
    setCategory("")
    setSelectedSubCategories([])
    setSubCategories([])
    setDescription("")
    setSelectedOption("")
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!templateType || !category) {
      return toast.error("Please select template type and category");
    } else if (!selectedSubCategories.length) {
      return toast.error("Choose at least one sub category");
    }

    const data = {
      // categoryId: selectedCategory,
      // subCategoryName: subcategoryName,
      templateType: templateType,
      category: category,
      subCategory: selectedSubCategories,
      description: description,
      image: image,
      mode: selectedOption,
    };

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(`template/${updateID}`, "PUT", data);
      if (res) {
        toast.success("Basic info updated successfully");
        resetData()
        setIsEdit(false);
        fetchData();
        // navigate('/subcategory')
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating subcategory");
      console.error("Error updating subcategory:", error);
    } finally {
      setIsLoading(false);
    }
  };



  const handleDelete = (id) => {
    const del = async () => {

      const data = { isDeleted: true };
      setIsLoading(true);
      try {
        const res = await HttpClientXml.requestData(`template/${id}`, "DELETE", data);
        if (res) {
          toast.success("Deleted Successfully");
          fetchData();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting subcategory");
        console.error("Error deleting subcategory:", error);
      } finally {
        setIsLoading(false);
      }
    };
    DeleteConfirmModal(del);
  };

  const [selectedSubCategories, setSelectedSubCategories] = useState([])

  const handleCheckboxChange = (e) => {
    // console.log("DDDDD", e.target.value);
    // Destructuring
    const { id, value, checked } = e.target;
    if (checked) {
      // specialcategoryTypes.push(value)
      setSelectedSubCategories([...selectedSubCategories, value]);
    }
    else {
      // specialcategoryTypes = specialcategoryTypes.filter((item) => item != value)
      let newFormValues = [...selectedSubCategories];
      //console.log("SERDS", id);
      const key = selectedSubCategories.indexOf(value);
      newFormValues.splice(key, 1);
      setSelectedSubCategories(newFormValues);
    }
    // setSelectedSubCategories(specialcategoryTypes)
    // console.log("SERSER", specialcategoryTypes);
  };

  const [imgLoader, setImgLoader] = useState(false)

  const [image, setImage] = useState(noimage);

  const imageHandler = async (e) => {
    setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload-template-image", "POST", data);
    //console.log("resImage", res);
    // return
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res?.data);
      setImage(res?.data);
      // setImage(`${res?.data}.${res?.extName}`);
      setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const [description, setDescription] = useState("")
  const paymentOptions = [
    { id: "Free", name: "Free" },
    { id: "Paid", name: "Paid" }
  ];
  const [selectedOption, setSelectedOption] = useState(paymentOptions[0].id);

  const handlePaymentOptionChange = (e) => {
    setSelectedOption(e.target.value); // Update state with the selected ID
  };



  //////***** Task & SubTask management */

  const initialTask = {
    // templateId: taskTemplateId,
    taskName: "",
    duration: 0,
    priority: "Default",
    subTaskList: [],
    displayOrder: 0,
    weightage: 1
    // subTaskList: [{ subTaskName: '', duration: 0, priority: 'Low' }]

  }

  const [tasks, setTasks] = useState([initialTask]);

  const [newTaskList, setNewTaskList] = useState([initialTask]);

  // Handle changes in the task input fields
  const handleTaskChange = (index, event) => {
    const { name, value } = event.target;
    const updatedTaskList = [...newTaskList];
    updatedTaskList[index][name] = value;

    if (name === "taskName") {
      updatedTaskList[index]["displayOrder"] = index + 1
    }

    //Weightage
    if (name === "priority") {
      if (value === "Default") {
        updatedTaskList[index]["weightage"] = 1
      } else if (value === "High") {
        updatedTaskList[index]["weightage"] = 1.75
      } else if (value === "Medium") {
        updatedTaskList[index]["weightage"] = 1.5
      } else if (value === "Low") {
        updatedTaskList[index]["weightage"] = 1.25
      }
    }

    setNewTaskList(updatedTaskList);
  };

  const handleSubTaskBlur = (taskIndex, subTaskIndex, event) => {
    const updatedTaskList = [...newTaskList];
    const subTaskDurations = updatedTaskList.map((task) =>
      task.subTaskList.reduce((sum, subTask) => sum + parseInt(subTask.duration), 0)
    );

    //console.log("LINE482 Task", Number(updatedTaskList[taskIndex].duration));
    //console.log("LINE482 SubTask", subTaskDurations[taskIndex]);

    const { name, value } = event.target;
    if (subTaskDurations[taskIndex] > updatedTaskList[taskIndex].duration) {
      toast.error("Total sub task duration should not greater than task duration.")
      updatedTaskList[taskIndex].subTaskList[subTaskIndex][name] = Number(value) - (subTaskDurations[taskIndex] - Number(updatedTaskList[taskIndex].duration));
      setNewTaskList(updatedTaskList);
    }

  };

  // Handle changes in the subtask input fields
  const handleSubTaskChange = (taskIndex, subTaskIndex, event) => {

    // const thisTasks = [...newTaskList];

    //
    // const subTaskDurations = thisTasks.map((task) =>
    //   task.subTaskList.reduce((sum, subTask) => sum + parseInt(subTask.duration), 0)
    // );



    // console.log("LINE493", thisTasks);

    // console.log("Tasks481",calculatedArray[taskIndex].subTaskTotalDuration,calculatedArray[taskIndex].taskDuration);
    const { name, value } = event.target;
    // let x = 0;
    // if (name === "duration") {
    //   x = calculatedArray[taskIndex].subTaskTotalDuration + Number(value);

    //   console.log("LINE499", x, value, calculatedArray[taskIndex].subTaskTotalDuration, calculatedArray[taskIndex].taskDuration);
    // }


    const updatedTaskList = [...newTaskList];
    updatedTaskList[taskIndex].subTaskList[subTaskIndex][name] = value;
    setNewTaskList(updatedTaskList);

    // if (calculatedArray[taskIndex].taskDuration > x) {

    //   const updatedTaskList = [...newTaskList];
    //   updatedTaskList[taskIndex].subTaskList[subTaskIndex][name] = value;
    //   setNewTaskList(updatedTaskList);
    // } else {
    //   toast.error(`Total subtasks duration should not be greater than task duration.`);
    // }


  };

  // Add more task input fields
  const addMoreTasks = () => {
    setNewTaskList([...newTaskList, { templateId: taskTemplateId, taskName: '', duration: 0, priority: 'Default', subTaskList: [], displayOrder: 0, weightage: 1 }]);
    // setNewTaskList([...newTaskList, { initialTask}]);
  };

  // Add more subtask input fields for a specific task
  const addMoreSubTasks = (taskIndex) => {

    const updatedTaskList = [...newTaskList];
    const subTaskDurations = updatedTaskList.map((task) =>
      task.subTaskList.reduce((sum, subTask) => sum + parseInt(subTask.duration), 0)
    );

    // console.log("LINE549 Task", Number(updatedTaskList[taskIndex].duration));
    // console.log("LINE549 SubTask", subTaskDurations[taskIndex]);

    if (subTaskDurations[taskIndex] === Number(updatedTaskList[taskIndex].duration)) {
      toast.error("Total sub tasks duration shount not greater than task duration.")
      return
    }


    // const updatedTaskList = [...newTaskList];
    updatedTaskList[taskIndex].subTaskList.push({ subTaskName: '', duration: 0, priority: 'Low' });
    setNewTaskList(updatedTaskList);
  };

  // Submit the new tasks
  const submitTasks = () => {
    setTasks([...tasks, ...newTaskList]);
    // setNewTaskList([{ taskName: '', duration: 0, priority: 'Low', subTaskList: [{ subTaskName: '', duration: 0, priority: 'Low' }] }]);

    const updatedTasks = newTaskList.map(task => {
      if (!task.templateId) {
        return {
          ...task,
          templateId: taskTemplateId
        };
      }
      return task;
    }).map(task => ({
      ...task,
      displayOrder: Number(task.displayOrder),
      duration: Number(task.duration),
      subTaskList: task.subTaskList.map(subTask => ({
        ...subTask,
        duration: Number(subTask.duration)
      }))
    }));

    // console.log("ALLTASKS", updatedTasks);
    saveTasks(updatedTasks)
    // fetchTasksByTemplate()
  };

  const [totalWeightage, setTotalWeightage] = useState(0)

  const saveTasks = async (taskArray) => {
    setIsLoading(true);
    console.log("LINE607",taskArray);
    // return
    try {
      const res = await HttpClientXml.requestData("template-task", "POST", taskArray);

      if (res) {
        toast.success("Tasks saved successfully");

        // Calculate the total weightage
        const totalWeightage = res.data.reduce((acc, task) => {
          return acc + (task.weightage || 0);
        }, 0);

        setTotalWeightage(totalWeightage)

        setDisplayManageTask(false)
        setDisplayMilestone(true)

        // fetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // toast.error("An error occurred while adding subcategory");
      // console.log(error);

    } finally {
      setIsLoading(false);
    }

  }

  // Delete a task from the new task list
  const deleteNewTask = (taskIndex) => {
    const updatedTaskList = newTaskList.filter((_, index) => index !== taskIndex);
    setNewTaskList(updatedTaskList);
  };

  // Delete a task from the existing tasks
  const deleteTask = (taskIndex) => {
    const updatedTasks = tasks.filter((_, index) => index !== taskIndex);
    setTasks(updatedTasks);
  };

  // Delete a subtask
  const deleteSubTask = (taskIndex, subTaskIndex) => {
    // const updatedTasks = [...tasks];
    const updatedTasks = [...newTaskList];

    //console.log("LINE631", updatedTasks, taskIndex, subTaskIndex);

    updatedTasks[taskIndex].subTaskList = updatedTasks[taskIndex].subTaskList.filter((_, index) => index !== subTaskIndex);
    setTasks(updatedTasks);
  };

  //////***** END Task & SubTask management */

  ///////////////////////** Milestone *///////////
  const initialMilestone = {
    // templateId: taskTemplateId,
    name: "",
    taskList: [],
    paymentPercentage: 0
  };
  const [milestones, setMilestones] = useState([initialMilestone]);

  const handleMilestoneBlur = (i, e) => {
    let newFormValues = [...milestones];
    // //console.log("LINE645",newFormValues);
    // return

    const totalPercentage = newFormValues.reduce((sum, item) => {
      return sum + Number(item.paymentPercentage);
    }, 0);

    //console.log("LINE645", newFormValues, Number(totalPercentage), e.target.value);

    if (Number(totalPercentage) > 100) {
      toast.error("Total payment percentage should be 100")
      // newFormValues[i][e.target.name] = "0"
      newFormValues[i][e.target.name] = Number(e.target.value) - (Number(totalPercentage) - 100);
      setMilestones(newFormValues);
      return
    }

    newFormValues[i][e.target.name] = e.target.value;
    setMilestones(newFormValues);
  }

  const handleMilestoneChange = (i, e) => {
    let newFormValues = [...milestones];

    newFormValues[i][e.target.name] = e.target.value;
    setMilestones(newFormValues);
  };
  const addMilestone = () => {
    // alert(mainTasks.length)

    let newFormValues = [...milestones];
    // console.log("LINE645",newFormValues);
    // return

    const totalPercentage = newFormValues.reduce((sum, item) => {
      return sum + Number(item.paymentPercentage);
    }, 0);

    // console.log("LINE645", newFormValues, Number(totalPercentage), e.target.value);

    if (Number(totalPercentage) === 100) {
      toast.error("Total payment percentage should be 100")
      return
    }

    if (milestones?.length < 500) {
      setMilestones([...milestones, initialMilestone]);
    } else {
      // Swal("")
      // Swal("Error", "Not more than 5000", "error");
    }
  };
  const deleteMilestone = (i, taskId) => {
    let newFormValues = [...milestones];
    newFormValues.splice(i, 1);
    setMilestones(newFormValues);
  };
  const handleSubmitMilestone = async () => {
    // console.log("milestones", milestones);
    const submitData = milestones.map(milestone => {
      return {
        ...milestone,
        paymentPercentage: Number(milestone.paymentPercentage)
      };
    })
      .map(task => {
        if (!task.templateId) {
          return {
            ...task,
            templateId: taskTemplateId
          };
        }
        return task;
      });

    /////////////
    const totalPercentage = submitData.reduce((sum, item) => {
      return sum + item.paymentPercentage;
    }, 0);

    const diff = 100 - totalPercentage

    const minPayment = Math.min(...submitData.map(item => item.paymentPercentage || Infinity));

    let updated = false;
    const updatedData = submitData.map(item => {
      if (!updated && item.paymentPercentage === minPayment) {
        updated = true;
        return { ...item, paymentPercentage: item.paymentPercentage + Number(diff) };
      }
      return item;
    });

    const totalPercentageAfterRectification = updatedData.reduce((sum, item) => {
      return sum + item.paymentPercentage;
    }, 0);





    if (Number(totalPercentageAfterRectification) !== 100) {
      toast.error("Total payment percentage should be 100")
      return
    }
    // console.log("LINE732", submitData);
    setIsLoading(true);
    //call API
    try {
      const res = await HttpClientXml.requestData("milestone", "POST", updatedData);

      if (res) {
        toast.success("Milestones saved successfully");
        setDisplayMilestone(false)
        setDisplayMessage(true)
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // toast.error("An error occurred while adding subcategory");
      // console.log(error);

    } finally {
      setIsLoading(false);
    }

  }
  //////////////// Modal //////////

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [selectedTasks, setSelectedTasks] = useState([])
  const [selectedTasksWeightage, setSelectedTasksWeightage] = useState([])
  const [milestoneIndex, setMilestoneIndex] = useState(0)

  const handleTaskCheckboxChange = (e) => {

    const { id, value, checked, dataset } = e.target;
    if (checked) {
      setSelectedTasks([...selectedTasks, value]);
      setSelectedTasksWeightage([...selectedTasksWeightage, dataset.weightage]);
    }
    else {
      let newFormValues = [...selectedTasks];
      const key = selectedTasks.indexOf(value);
      newFormValues.splice(key, 1);
      setSelectedTasks(newFormValues);

      let newFormValuesWeightage = [...selectedTasksWeightage];
      const keyWeightage = selectedTasksWeightage.indexOf(dataset.weightage);
      newFormValuesWeightage.splice(keyWeightage, 1);
      setSelectedTasksWeightage(newFormValuesWeightage);
    }
  }

  const handleSelectedTaskList = () => {
    // console.log("milestones", milestones);
    const updatedMilestones = milestones.map((milestone, index) => {

      // Calculate total Weightage
      const totalSelectedWeightage = selectedTasksWeightage.reduce((acc, curr) => {
        return acc + parseFloat(curr);
      }, 0);
      const percentageVal = Math.round((100 / totalWeightage) * totalSelectedWeightage)
      const calculatedPaymentPercentage = Math.round(percentageVal * 100) / 100

      //console.log("LINE826", totalSelectedWeightage, totalWeightage);

      if (index === milestoneIndex) {
        return {
          ...milestone,
          taskList: selectedTasks,
          paymentPercentage: calculatedPaymentPercentage,
        };
      }
      return milestone;
    });
    setMilestones(updatedMilestones)




    setSelectedTasks([])
    setSelectedTasksWeightage([])
    // //console.log("LINE828", updatedMilestones);

  }

  const [chooenTasks, setChooenTasks] = useState([])
  const [chooenTasksForMilestone, setChooenTasksForMilestone] = useState([])
  const getChoosenTasks = (i) => {
    const allTaskIds = milestones.flatMap(milestone => milestone.taskList);
    setChooenTasks(allTaskIds)
    setChooenTasksForMilestone(milestones[i]?.taskList)
    //console.log("LINE849", allTaskIds, milestones[i]);

  }



  return (
    <div>
      <CustomLoader loading={isLoading} />

      <div className="d-flex justify-content-end">
        <div className="form-header">

          <section className="piechartsBox_area">
            <div style={headLineStyle} className="page-headline">
              Create Template
            </div>

          </section>

          {
            //Basic Information
            displayBasicInfo && (
              <>
                <section className="piechartsBox_area">
                  <form className="mb-2">
                    <div className="row">
                      <div className="col-4">
                        <div class="form-group">
                          <label for="formFile" class="form-label" style={{ display: "block" }}>
                            Template Image
                          </label>
                          {image && !imgLoader && (
                            <>
                              <img
                                style={{
                                  maxHeight: "256px",
                                  marginTop: "12px",
                                  borderRadius: "9px",
                                }}
                                src={image}
                              />
                            </>
                          )}
                          {
                            imgLoader && (<><img src={loadingimage} /></>)
                          }
                          <input
                            id="images"
                            onChange={imageHandler}
                            class="form-control"
                            type="file"
                            accept="image/*"
                          />


                        </div>

                        <div className="form-group">
                          <label style={{ display: "block" }}>Payment type:</label>
                          {paymentOptions.map((option) => (
                            <div className="form-check form-check-inline" key={option.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentOption"
                                id={`option-${option.id}`}
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={handlePaymentOptionChange}
                              />
                              <label className="form-check-label" htmlFor={`option-${option.id}`}>
                                {option.name}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div className="form-group d-flex _align-items-center _justify-content-end">
                          {!isEdit ? (
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className="btn"
                              style={{
                                background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                                color: "#fff",
                                height: "50px",
                                textAlign: "center",
                                marginTop: "25px"
                              }}
                            >
                              Save & Continue &#8594;
                            </button>
                          ) : (
                            <div className="d-flex align-items-center" style={{ gap: '7px' }}>
                              <button
                                type="submit"
                                onClick={handleEditSubmit}
                                className="btn-grad"
                                style={{
                                  background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                                  color: "#fff",
                                  height: "40px",
                                  width: "200px",  // Adjust width as needed
                                  textAlign: "center",
                                  marginTop: "25px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                Update Basic Information
                              </button>
                              {/* <ImCross
                                onClick={() => { setIsEdit(false); resetData(); }}
                                style={{
                                  fontSize: "30px",
                                  cursor: "pointer",
                                  marginTop: "25px",  // Align with button margin
                                  lineHeight: "1"  // Ensure no extra line height affects alignment
                                }}
                                className="text-danger ms-2"
                              /> */}
                            </div>

                          )}
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col-6">
                            <label htmlFor="templateType" className='edit-tag'>
                              Template Type<span className="text-danger">*</span>
                            </label>
                            <select
                              id="templateType"
                              className="form-control"
                              onChange={handleTemplateTypeChange}
                              value={templateType.charAt(0).toUpperCase() + templateType.slice(1)}
                            >
                              <option value="">Select Template Type</option>
                              {templateTypes.map((item, key) => (
                                <option key={key} value={item._id} className="text-capitalize">
                                  {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                                </option>
                              ))}
                              <option value="addnew">+ Add New Type</option>
                            </select>
                          </div>

                          <div className="col-6">
                            <label htmlFor="category" className='edit-tag'>
                              Select Category<span className="text-danger">&nbsp;*</span>
                            </label>
                            <select
                              id="category"
                              className="form-control"
                              onChange={handleCategoryChange}
                              value={category}
                            >
                              <option value="">Select a Category</option>
                              {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                  {category.categoryName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-12">
                            <label htmlFor="categoryDropdown" className='edit-tag'>
                              Choose Subcategories
                            </label>
                            <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">
                              {
                                subCategories.length > 0 ?
                                  subCategories.map((item, index) => {
                                    return (
                                      <>
                                        <div class="form-check-inline" key={index}>
                                          <input class="form-check-input" type="checkbox" value={item?._id} id={index}
                                            onChange={(e) => handleCheckboxChange(e)}
                                            checked={selectedSubCategories.includes(item?._id)}
                                          />
                                          <label class="form-check-label" for="flexCheckDefault">
                                            {item?.subCategoryName}
                                          </label>
                                        </div>
                                      </>
                                    )
                                  }) : (
                                    <>Please select a category</>
                                  )
                              }
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group mb-2 mt-1">
                              <label for="inputEmail4">
                                Description:
                              </label>
                              <textarea class="form-control"
                                name="description"
                                placeholder={`Enter a short description`}
                                value={description || ""}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="7"
                              ></textarea>

                            </div>
                          </div>


                        </div>

                      </div>

                    </div>

                  </form>
                </section>
              </>
            )
          }
          {
            //Manage Tasks & Sub Tasks
            displayManageTask && (
              <>
                <section className="piechartsBox_area">

                  <h4 className="my-3">
                    Create <span className="text-primary">Tasks & Subtasks</span> for the template
                  </h4>


                  {newTaskList.map((task, taskIndex) => (

                    <div key={taskIndex} style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">
                      <div className="row">
                        <div className="col-1">
                          <div className="form-group mb-2 mt-1">
                            <label for="displayOrder">
                              Order:
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              name="displayOrder"
                              placeholder={`Order `}
                              value={task.displayOrder || taskIndex + 1}
                              onChange={(e) => handleTaskChange(taskIndex, e)}
                            />

                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group mb-2 mt-1">
                            <label for="taskName">
                              Task Name:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="taskName"
                              placeholder={`Task Name `}
                              value={task.taskName || ""}
                              onChange={(e) => handleTaskChange(taskIndex, e)}
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group mb-2 mt-1">
                            <label for="duration">
                              Duration <span style={{ fontSize: "80%" }}>(in Days)</span>:
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              name="duration"
                              placeholder={`Duration `}
                              value={task.duration || ""}
                              onChange={(e) => handleTaskChange(taskIndex, e)}
                            />

                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group mb-2 mt-1">
                            <label for="priority">
                              Priority:
                            </label>
                            <select
                              name="priority"
                              className="form-control"
                              value={task.priority}
                              onChange={(e) => handleTaskChange(taskIndex, e)}>
                              <option value="">- Choose -</option>
                              {["Default", "High", "Medium", "Low"].map((item, key) => (
                                <option key={key} value={item}
                                  selected={item === task.priority ? true : false}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>


                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteNewTask(taskIndex)}
                          >
                            <i class="fas fa-trash"></i><br />Delete Task
                          </button>
                        </div>
                      </div>

                      {task.subTaskList.map((subTask, subTaskIndex) => (
                        <>
                          <div key={subTaskIndex} style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }}>
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group mb-2 mt-1">
                                  <label for="taskName">
                                    Sub Task Name:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="subTaskName"
                                    placeholder={`Sub Task Name `}
                                    value={subTask.subTaskName || ""}
                                    onChange={(e) => handleSubTaskChange(taskIndex, subTaskIndex, e)}
                                  />
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="form-group mb-2 mt-1">
                                  <label for="duration">
                                    Duration <span style={{ fontSize: "80%" }}>(in Days)</span>:
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    name="duration"
                                    placeholder={`Duration `}
                                    value={subTask.duration || ""}
                                    onChange={(e) => handleSubTaskChange(taskIndex, subTaskIndex, e)}
                                    onBlur={(e) => handleSubTaskBlur(taskIndex, subTaskIndex, e)}
                                  />

                                </div>
                              </div>
                              <div className="col-2">
                                {/* <div className="form-group mb-2 mt-1">
                                  <label for="priority">
                                    Priority:
                                  </label>
                                  <select
                                    name="priority"
                                    className="form-control"
                                    value={subTask.priority}
                                    onChange={(e) => handleSubTaskChange(taskIndex, subTaskIndex, e)}
                                  >
                                    <option value="">- Choose -</option>
                                    {["High", "Medium", "Low"].map((item, key) => (
                                      <option key={key} value={item}
                                        selected={item === subTask.priority ? true : false}
                                      >
                                        {item}
                                      </option>
                                    ))}
                                  </select>


                                </div> */}
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger ml-1"
                                  onClick={() => deleteSubTask(taskIndex, subTaskIndex)}
                                >
                                  <i class="fas fa-trash"></i><br />Delete Sub Task
                                </button>
                              </div>

                            </div>

                          </div>

                        </>
                      ))}
                      <div className="button-section my-2">
                        <button
                          className="btn btn-sm btn-success"
                          type="button"
                          onClick={() => addMoreSubTasks(taskIndex)}
                        >
                          <i class="fas fa-plus"></i> SubTask
                        </button>

                      </div>

                    </div>

                  ))}
                  <div className="button-section my-2">
                    <button
                      className="btn btn-sm btn-success"
                      type="button"
                      onClick={addMoreTasks}
                    >
                      <i class="fas fa-plus"></i> Task
                    </button>

                  </div>
                  <button
                    type="submit"
                    onClick={submitTasks}
                    className="btn"
                    style={{
                      background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                      color: "#fff",
                      height: "50px",
                      textAlign: "center",
                      marginTop: "25px"
                    }}
                  >Save & Continue &#8594;</button>
                </section>
              </>
            )
          }
          {
            //Create Milestone
            displayMilestone && (
              <>
                <section className="piechartsBox_area">
                  <h4 className="my-3">
                    Create <span className="text-primary">Milestone(s)</span> for the template
                    {/* <br />Weightage: {totalWeightage} */}
                  </h4>

                  {milestones.map((milestone, index) => (
                    <div key={index} style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }}>
                      <div className="row">
                        <div className="col-6">

                          <div className="form-group mb-2 mt-1">
                            <label for="inputEmail4">
                              Milestone Name:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="name"
                              placeholder={`Milestone Name`}
                              value={milestone.name || ""}
                              onChange={(e) => handleMilestoneChange(index, e)}
                            />
                          </div>

                        </div>

                        <div className="col-2">
                          <label for="inputEmail4">
                            Choose Task(s):
                          </label>
                          {/* <input
                            type="text"
                            class="form-control"
                            name="choosetask"
                            placeholder={`Choose task`}
                            onFocus={() => {
                              getChoosenTasks(index)
                              setMilestoneIndex(index)
                              handleShow()
                            }}
                          /> */}
                          <button className="btn btn-primary btn-sm"
                            onClick={() => {
                              getChoosenTasks()
                              setMilestoneIndex(index)
                              handleShow()
                            }}
                          >
                            Choose Task(s)
                          </button>
                        </div>


                        <div className="col-2">

                          <div className="form-group mb-2 mt-1">
                            <label for="inputEmail4">
                              Payment Percentage:
                            </label>
                            <input
                              type="number"
                              class="form-control"
                              name="paymentPercentage"
                              placeholder={`Payment Percentage`}
                              value={milestone.paymentPercentage || ""}
                              onChange={(e) => handleMilestoneChange(index, e)}
                              onBlur={(e) => handleMilestoneBlur(index, e)}
                            />
                          </div>

                        </div>

                        <div className="col-2 d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger ml-1"
                            onClick={() => deleteMilestone(index)}
                          >
                            <i class="fas fa-trash"></i><br />Remove Milestone
                          </button>
                        </div>

                      </div>
                    </div>
                  ))}
                  <div className="button-section my-2">
                    <button
                      className="btn btn-sm btn-success"
                      type="button"
                      onClick={addMilestone}
                    >
                      <i class="fas fa-plus"></i> Milestone
                    </button>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmitMilestone}
                    className="btn"
                    style={{
                      background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                      color: "#fff",
                      height: "50px",
                      textAlign: "center",
                      marginTop: "25px"
                    }}
                  >Finish</button>
                </section>
              </>
            )
          }
          {
            //Message
            displayMessage && (
              <>
                <section className="piechartsBox_area">
                  <h4 className="my-3 text-success">
                    Congratulations!
                  </h4>
                  <p>You have successfully created the template</p>
                  <button className="btn btn-primary"
                    onClick={() => {
                      setDisplayMessage(false)
                      setDisplayBasicInfo(true)
                    }}
                  >Create another</button>
                </section>
              </>
            )
          }
          {
            //Template Listing
            !isEdit && displayTemplateListing && (
              <>
                <section className="piechartsBox_area">
                  <div className="mt-2">
                    <DataTable
                      columns={columns}
                      data={tableData}
                      pagination
                      striped
                      customStyles={customStyles}
                    />
                  </div>
                </section>
              </>
            )
          }
          {
            //Modal
            show && (
              <div className="modal-overlay" onClick={handleClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <span className="close-button" onClick={handleClose}>&times;</span>
                  <h2>Choose Task(s)</h2>
                  {
                    tasksByTemplate.length > 0 ?
                      tasksByTemplate.map((item, index) => {
                        return (
                          <>
                            <div class="form-check-inline" key={index}>
                              <input class="form-check-input"
                                type="checkbox"
                                value={item?._id}
                                id={index}
                                data-weightage={item?.weightage}
                                onChange={(e) => {
                                  handleTaskCheckboxChange(e)
                                }}
                                disabled={chooenTasks.includes(item?._id)}
                              // checked={chooenTasksForMilestone.includes(item?._id)}
                              />
                              <label class="form-check-label" for="flexCheckDefault">
                                <span
                                  className="text-decoration-line-through"
                                >{item?.taskName}</span>
                              </label>
                            </div>
                          </>
                        )
                      }) : (
                        <>Please select a category</>
                      )
                  }
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      handleSelectedTaskList()
                      handleClose()
                    }}
                  >
                    Set
                  </button>
                </div>
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default Templates;
