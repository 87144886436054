import React, { useEffect, useState } from 'react';
import CustomLoader from '../../loader/CustomLoader';
import { headLineStyle } from '../../Layout/HeadlineStyle';
import DataTable from 'react-data-table-component';
import HttpClientXml from '../../utils/HttpClientXml';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import { ImCross } from 'react-icons/im';
import EditDeleteIcon from '../../CustomComponents/EditDeleteIcon';
import { DeleteConfirmModal } from '../../loader/DeleteConfirmModal';

const customStyles = {
  rows: {
    style: {
      minHeight: '48px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      backgroundColor: '#cee0eb',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

const ServiceImages = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subcategoryName, setSubcategoryName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const columns = [
    { name: 'SL', selector: row => row.sl },
    { name: 'Service Name', selector: row => row.Category },
    { name: 'Subservice Name', selector: row => row.Subcategory },
    { name: 'Date of Creation', selector: row => row.createdAt },
    { name: 'Action', selector: row => row.action },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [categoriesRes, subCategoriesRes] = await Promise.all([
        HttpClientXml.requestData('get-all-service-category', "GET", {}),
        HttpClientXml.requestData('subCategory', "GET", {})
      ]);

      if (categoriesRes && categoriesRes.status) {
        setCategories(categoriesRes.data);
      } else {
        toast.error("Failed to fetch categories");
      }

      if (subCategoriesRes && subCategoriesRes.data) {
        const apiData = subCategoriesRes.data.map((item, i) => {
          const category = categoriesRes.data.find(cat => cat._id === item.categoryId);
          return {
            sl: i + 1,
            Category: category ? category.categoryName : 'Unknown',
            Subcategory: item.subCategoryName,
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            action: (
              <EditDeleteIcon
                onClickEdit={() => handleEdit(item)}
                onClickDelete={() => handleDelete(item._id)}
              />
            ),
          };
        });

        setTableData(apiData.reverse());
      } else {
        toast.error("Failed to fetch subcategories");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fectchServiceData = async () => {
    setIsLoading(true);
    const data = {
      serviceID: selectedCategory,
    }
    try {
      const res = await HttpClientXml.requestData("get-serviceImage", "POST", data);
      console.log("DATA101", res?.data);



      if (res) {
        // toast.success("Images Added Successfully");
        // setSelectedCategory('');
        // setSubcategoryName('');
        // navigate('/subcategory')
        // fetchData();
        if (res?.data?.length > 0) {
          setServiceImages(res?.data[0].images)
          setIsEdit(true)
        } else {
          setServiceImages([])
          setIsEdit(false)
        }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding subcategory 115");
      console.error("Error adding subcategory:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fectchServiceData()
  }, [selectedCategory])

  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);

  const handleSubcategoryChange = (e) => setSubcategoryName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      return toast.error("Please select a service");
    }
    if (serviceImages.length <= 0) {
      return toast.error("No image added");
    }

    const data = {
      serviceID: selectedCategory,
      images: serviceImages,
    };

    // console.log("118DATA", data);
    // return

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData("serviceImage", "POST", data);
      if (res) {
        toast.success("Images Added Successfully");
        // setSelectedCategory('');
        // setServiceImages([]);
        // navigate('/subcategory')
        // fetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding subcategory");
      console.error("Error adding subcategory:", error);
    } finally {
      fectchServiceData()
      setIsLoading(false);
    }
  };

  const handleEdit = (item) => {
    setIsEdit(true);
    setSelectedCategory(item.categoryId);
    setSubcategoryName(item.subCategoryName);
    setUpdateID(item._id);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory) {
      return toast.error("Please select a service");
    }

    const data = {
      serviceID: selectedCategory,
      images: serviceImages,
    };

    // console.log("118DATA", data);
    // return

    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData('edit-service-image', "POST", data);
      if (res) {
        toast.success("Images Updated Successfully");
        // setSelectedCategory('');
        // setServiceImages([]);
        // navigate('/subcategory')
        // fetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding subcategory");
      console.error("Error adding subcategory:", error);
    } finally {
      fectchServiceData()
      setIsLoading(false);
    }
  };

  const handleDelete = (id) => {
    const del = async () => {
      const data = { isDeleted: true };
      setIsLoading(true);
      try {
        const res = await HttpClientXml.requestData(`subCategory/${id}`, "PUT", data);
        if (res) {
          toast.success("Deleted Successfully");
          fetchData();
          navigate('/subcategory')
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error("An error occurred while deleting subcategory");
        console.error("Error deleting subcategory:", error);
      } finally {
        setIsLoading(false);
      }
    };
    DeleteConfirmModal(del);
  };


  // Includes ///////////////
  const initialIncludes = [];

  const [serviceImages, setServiceImages] = useState(initialIncludes);

  const handleIncludesChange = (i, e) => {
    let newFormValues = [...serviceImages];
    newFormValues[i] = e.target.value;
    setServiceImages(newFormValues);
  };

  const [serviceWorkImageLoader, setServiceWorkImageLoader] = useState(false)
  const handleServiceWorkImage = async (i, e) => {
    setServiceWorkImageLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log('L86:', file);
    let res = await HttpClientXml.fileUplode("upload-template-image", "POST", data);

    if (res && res.status) {
      console.log("UploadImageRes", res);
      // setBannerImage(res?.url);
      let newFormValues = [...serviceImages];
      newFormValues[i] = res?.data;
      setServiceImages(newFormValues);
      // console.log("L60: ",howToServiceWork[0].image);
    } else {
      toast.error(res?.message);
    }
    setServiceWorkImageLoader(false);
  };


  const addIncludes = () => {
    if (serviceImages.length < 5000) {
      setServiceImages([...serviceImages, initialIncludes]);
    } else {
      // Swal("")
      // Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeIncludes = (i) => {
    let newFormValues = [...serviceImages];
    newFormValues.splice(i, 1);
    setServiceImages(newFormValues);
  };

  // //Includes ///////////////

  return (
    <div>
      <CustomLoader loading={isLoading} />

      <div className="d-flex justify-content-end">
        <div className="form-header">
          <section className="piechartsBox_area">
            <div style={headLineStyle} className="page-headline">
              Service Images
            </div>
            <form className="mb-2">
              <div className="row">
                <div className="col-4 form-inline">
                  <label htmlFor="categoryDropdown" className='edit-tag'>
                    Select Service<span className="text-danger">&nbsp;*</span>
                  </label>
                  <select
                    id="categoryDropdown"
                    className="form-control"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    <option value="">Select a Service</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                  {
                    selectedCategory &&
                    <button
                      className="btn btn-sm btn-success"
                      type="button"
                      onClick={() => addIncludes()}
                    >
                      <i class="fas fa-plus"></i> Image
                    </button>
                  }

                </div>

              </div>
              <div className="row">
                {/* <div className="_col-4"> */}
                {/* <label htmlFor="subcategoryInput" className='edit-tag'>
                    SubService Name<span className="text-danger">&nbsp;*</span>
                  </label> */}
                {/* <input
                    type="text"
                    id="subcategoryInput"
                    className="form-control"
                    placeholder="Subservice Name"
                    onChange={handleSubcategoryChange}
                    value={subcategoryName}
                    name="subcategory"
                  /> */}
                {serviceImages?.map((element, index) => (

                  <div
                    // style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} 
                    className="_form-inline col-4 my-2" key={index}>

                    {element != "" && (
                      <>
                        <div id={index}>
                          <img
                            style={{
                              maxHeight: "100px",
                              maxWidth: "100%",
                              // marginTop: "12px",
                              borderRadius: "5px",
                            }}
                            src={element}
                          />

                        </div>
                      </>
                    )}
                    <div className="form-group mb-2 mt-1">
                      <input
                        class="form-control"
                        onChange={(e) => handleServiceWorkImage(index, e)}
                        type="file"
                        id="thumbnail"
                        accept="image/*"
                      />
                    </div>

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger _ml-1 mt-2"
                        onClick={() => removeIncludes(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}
                {/* <div className="button-section mt-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addIncludes()}
                  >
                    <i class="fas fa-plus"></i> Image
                  </button>

                </div> */}
                {/* </div> */}
              </div>
              {
                selectedCategory &&
                <div className="row">
                  {/* <div className="col-4 d-flex align-items-center justify-content-center"> */}
                  <div className="col-4">
                    {!isEdit ? (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn-grad w-50 d-flex justify-content-center align-items-center"
                        style={{
                          background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                          color: "#fff",
                          height: "40px",
                          textAlign: "center",
                          marginTop: "25px"
                        }}
                      >
                        Save Images
                      </button>
                    ) : (
                      <div className="d-flex align-items-center" style={{ gap: '7px' }}>
                        <button
                          type="submit"
                          onClick={handleEditSubmit}
                          className="btn-grad"
                          style={{
                            background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                            color: "#fff",
                            height: "40px",
                            width: "200px",  // Adjust width as needed
                            textAlign: "center",
                            marginTop: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          Update Images
                        </button>
                        <ImCross
                          onClick={() => { setIsEdit(false); setSelectedCategory(''); setServiceImages([]); }}
                          style={{
                            fontSize: "30px",
                            cursor: "pointer",
                            marginTop: "25px",  // Align with button margin
                            lineHeight: "1"  // Ensure no extra line height affects alignment
                          }}
                          className="text-danger ms-2"
                        />
                      </div>

                    )}
                  </div>

                </div>
              }



            </form>

            {/* <div className="mt-2">
              <DataTable
                columns={columns}
                data={tableData}
                pagination
                striped
                customStyles={customStyles}
              />
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default ServiceImages;
