import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/Component/Modal/Modal.css";
import Home from "../src/View/Home/Index";
import Login from "./Athentication/Login";
import MainLayout from "./Layout/MainLayout";
import PrivateRouter from "./privateRouter/PrivateRouter";
import NotFound from "./NotFound/NotFound";
import SubscribedUser from "./Pages/Subscribed User/SubscribedUser";
import Alluser from "./Pages/User/Alluser";
import Category from "./Pages/Category/Category";
import WithDrawal from "./Pages/Withdrawal/WithDrawal";
import Contractor from "./Pages/User/Contractor";
import Homeowner from "./Pages/User/Homeowner";
import SubContractor from "./Pages/User/SubContractor";
import Financer from "./Pages/User/Financer";
import Project from "./Pages/projects/Project";
import SingleProjectDetails from "./Pages/projects/SingleProjectDetails";
import AddTask from "./Pages/Task/AddTask";
import { Toaster } from "react-hot-toast";
import SubCategory from "./Pages/SubCategory/SubCategory";
import AddSubTask from "./Pages/SubTask/AddSubTask";
import TemplateTypes from "./Pages/Template/TemplateTypes";
import Templates from "./Pages/Template/Templates";
import ManageTemplates from "./Pages/Template/ManageTemplates";
import Support from "./Pages/Support/Support";
import ViewTickets from "./Pages/Support/ViewTickets";
import Invitation from "./Pages/Invitation/Invitation";
import ServiceImages from "./Pages/ServiceImages/ServiceImages";


const App = () => {

  return (
    <>
      <BrowserRouter>
        <Toaster />

        <Routes>

          <Route element={<PrivateRouter />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} />

              {/* User */}
              <Route path="/subscribed-user" element={<SubscribedUser />} />
              <Route path="/all-user" element={<Alluser />} />
              <Route path="/user-contractor" element={<Contractor />} />
              <Route path="/user-homeowner" element={<Homeowner />} />
              <Route path="/user-sub-contractor" element={<SubContractor />} />
              <Route path="/user-financer" element={<Financer />} />

              {/* Category */}
              <Route path="/categories" element={<Category />} />
              <Route path="/subcategory" element={<SubCategory />} />
              <Route path="/serviceimages" element={<ServiceImages />} />

              {/* Withdrawal */}
              <Route path="/withdrawal" element={<WithDrawal />} />
              <Route path="/projects" element={<Project />} />
              <Route path="/single-project/:id" element={<SingleProjectDetails />} />
              <Route path="/add-task-name" element={<AddTask />} />
              <Route path="/add-subtask-name" element={<AddSubTask />} />
              {/* <Route path="/dashboard" element={<Home />} /> */}

              {/* Template */}
              <Route path="/template-types" element={<TemplateTypes />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/manage-templates" element={<ManageTemplates />} />
              {/* Support */} 
              <Route path="/add-support-type" element={<Support />} />
              <Route path="/view-tickets" element={<ViewTickets />} />
              {/* Invitation */} 
              <Route path="/invitation" element={<Invitation />} />

            </Route>
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
